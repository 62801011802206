<template>

    <div class="mb-6 last-of-type:mb-0">
        <div :class="background" class="p-6 rounded-lg block w-full cursor-pointer " @click="expand">
            <div class="flex flex-wrap items-center">
                <div>
                    <h3 class="text-left body-m mb-2">{{ subheading }}</h3>
                    <h4 class="heading-xs md:heading-s">{{ title }}</h4>
                </div>
                <div class="ml-auto footer-dict-expand self-center transition-all" ref="icon">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="20" fill="#FFF200"/>
                    <path d="M22.222 22.222H28.8887C30.1109 22.222 31.1109 21.222 31.1109 19.9998C31.1109 18.7776 30.1109 17.7776 28.8887 17.7776H22.222V11.1109C22.222 9.88867 21.222 8.88867 19.9998 8.88867C18.7776 8.88867 17.7776 9.88867 17.7776 11.1109V17.7776H11.1109C9.88867 17.7776 8.88867 18.7776 8.88867 19.9998C8.88867 21.222 9.88867 22.222 11.1109 22.222H17.7776V28.8887C17.7776 30.1109 18.7776 31.1109 19.9998 31.1109C21.222 31.1109 22.222 30.1109 22.222 28.8887V22.222Z" fill="#38332C"/>
                    </svg>
                </div>
            </div>

            <div class="hidden text-left mt-4 w-full body max-w-[800px]" ref="content">
                <slot></slot>
            </div>

        </div>

    </div>

</template>

<script>

    export default {
        props: {
            subheading: String,
            title: String,
            content: String,
            background: String
        },
        methods: {
            expand() {
                this.$refs.content.classList.toggle('hidden');
                this.$refs.icon.classList.toggle('rotate-45');
            }
        }
    }

</script>