<template>
    <div class="relative video-hover group overflow-hidden rounded-lg" :class="{'not-played': ! hasPlayed}">
        <video v-if="type=='file'" crossorigin playsinline ref="player" class="aspect-video bg-cover bg-center">
            <source :src="url" type="video/mp4">
        </video>
        <div v-if="poster && !isPlaying" class="bg-cover bg-center aspect-video absolute inset-0 w-full h-full">
            <img :src="poster" alt="poster" class="w-full h-full object-cover" />
        </div>
        <div v-if="type=='youtube'" class="plyr__video-embed" id="player" ref="player">
            <iframe
                :src="'https://www.youtube.com/embed/' + id + '?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;modestbranding=1&amp;rel=0&amp;enablejsapi=1'"
                allowfullscreen
                allowtransparency
                allow="autoplay"
            ></iframe>
        </div>
        <div class="flex absolute bottom-10 left-8 z-[2]">
            <button @click="togglePlay()" v-if="! hasPlayed" class="flex flex-col items-center">
                <div class="h-[68px] w-[68px] flex flex-col items-center justify-center bg-yellow rounded-full transition-all group-hover:bg-white">
                    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.22217 2L17.7777 12L2.22217 22V2Z" fill="#38332C" stroke="#38332C" stroke-width="2.61111" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <p class="font-medium text-center mt-2 text-white">{{text}}</p>
            </button>
        </div>
        <div v-if="!isPlaying" class="absolute top-0 left-0 right-0 bottom-0 w-full z-[1] cursor-pointer transition-all opacity-20 bg-black" :class="{'pointer-events-none group-hover:opacity-0': hasPlayed}" @click="togglePlay()"></div>
    </div>
</template>

<script>
import Plyr from 'plyr'

export default {
    props: ['type', 'url', 'id', 'poster', 'text'],
    data: () => ({
        player: null,
        isPlaying: false,
        hasPlayed: false
    }),
    mounted() {
        this.player = new Plyr(this.$refs.player, {controls: ['play', 'progress', 'current-time', 'mute', 'fullscreen']});
    },

    methods: {
        togglePlay() {
            this.hasPlayed = true
            this.isPlaying = !this.isPlaying;
            this.player.togglePlay();
        }
    },

    computed: {
      backgroundImage() {
        return `background-image:url(${this.poster})`
      }
    }
}
</script>
