// Our main CSS
import '../css/app.css'
// layzyload and/or play/pause inline videos when they are in/out of view
const autoplaying_videos = document.querySelectorAll('video[playsinline]')

const animatedLinks = document.querySelectorAll('.animated-links-container')
if (animatedLinks && animatedLinks.length) {
    import('./modules/animated-links').then((module) => {
        module.default(animatedLinks)
    })
}
const animatedLinksVariant = document.querySelectorAll('.animated-links-container-variant')
if (animatedLinksVariant && animatedLinksVariant.length) {
  import('./modules/animated-links-variant').then((module) => {
      module.default(animatedLinksVariant)
  })
}

const largeAnimatedText = document.querySelectorAll('.large-animated-text')
if (largeAnimatedText && largeAnimatedText.length) {
  import('./modules/large-animated-text').then((module) => {
      module.default(largeAnimatedText)
  })
}


const historyTimeline = document.querySelectorAll('.js-timeline')
if (historyTimeline && historyTimeline.length) {
  import('./modules/timeline').then((module) => {
      module.default(historyTimeline)
  })
}

const stockPrices = document.querySelectorAll('#stock-prices')
if (stockPrices && stockPrices.length) {
  import('./modules/stock-prices').then((module) => {
      module.default(stockPrices)
  })
}

import('./modules/slider').then((module) => {
  module.default()
})

if (autoplaying_videos && autoplaying_videos.length) {
    import('./modules/inline-videos').then((module) => {
      module.default(autoplaying_videos)
    })
}

import { createApp } from 'vue'

import Faq from './components/Faq.vue'
import Search from './components/Search.vue'
import VideoPlayer from './components/VideoPlayer.vue'
window.createVue = createApp;
const vueSingleFileComponents = {
  'faq': Faq,
  'search': Search,
  'video-player': VideoPlayer
}
window.vueSingleFileComponents = vueSingleFileComponents
const vue = document.querySelectorAll('.js-vue')
if (vue && vue.length) {
  vue.forEach((el) => {
    const app = createApp({
      components: vueSingleFileComponents
    })
    app.mount(el)
  })
}
const menu = createApp({

  components: {
    'search': Search
  },

  data: () => ({
    active: null,
    modalActive: null,
  }),

  watch: {
    active: {
      handler(newValue) {
        if (newValue === null) {
          return document.body.classList.remove('menu-open', 'overlay');
        }
        return document.body.classList.add('menu-open')
      },
      immediate: true
    }
  },

  methods: {
    toggle(name) {
      document.body.classList.add('overflow-hidden')
      if (this.active === name) {
        document.body.classList.remove('overflow-hidden')
        return this.active = null
      }
      this.active = name
    },
    handleScroll() {
      const nav = this.$refs.nav;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > 2) {
        nav.classList.add('scrolled');
      }
      else {
        nav.classList.remove('scrolled');
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
})

menu.mount('#main-menu')



/**
 * Vue is ready for you, if you need it.
 */

/**
 * The following block of code automatically registers your Vue components.
 * It will recursively scan this directory for the Vue components and
 * automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *
 * Feel free to remove this block if you don't like black magic fuckery,
 * or you want to register your Vue components manually.
 */
/*
 *** https://v3.vuejs.org/
 */
// interface ImportedModule {
//   name: string
//   default: object
// }
// const vueSingleFileComponents = import.meta.glob<ImportedModule>('./**/*.vue', {
//   eager: true
// })

// import { createApp } from 'vue'
// const app = createApp({})

// for (const path in vueSingleFileComponents) {
//   const importedModule = vueSingleFileComponents[String(path)]
//   const name = path.split('/').pop()?.split('.')[0]
//   if (name !== undefined && typeof name === 'string') {
//     app.component(name, importedModule.default)
//   }
// }
// app.mount('#vue-app')

/**
OR:

Load a js-module dynamically, if the parent element exists, in this example, a vue component. (The js-file wont load if html element with id #ExampleVueComponentWrapper does not exists)
*/

/**
const ExampleVueComponentWrapper = document.getElementById(
  'ExampleVueComponentWrapper'
)
if (ExampleVueComponentWrapper && ExampleVueComponentWrapper !== null) {
  import('./ExampleVueComponent/index.js')
    .then((module) => {
      module.default(ExampleVueComponentWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}
*/

/**
 * 🦄: Now do your magic.
 */
