<template>
  <div class="search-component">
    <input
      type="text"
      class="search-input heading-s md:heading-l outline-transparent w-full outline-none py-4 bg-brown text-white placeholder-white border-b-white border-b"
      v-model="query"
      @input="performSearch"
      :placeholder="placeholder"
    />
    <ul class="flex pt-8 flex-col space-y-2" v-if="results.length > 0">
      <li v-for="result in results" :key="result.id">
        <a class="p-4 md:p-6 bg-white transition-all group text-brown hover:bg-yellow heading-xs md:heading-s rounded-lg flex items-center justify-between" :href="result.url">
          <h3>{{ result.title }}</h3>
          <div class="font-medium text-base flex space-x-1 items-center mt-2">
              <span>Läs mer</span>
              <svg class="opacity-0 transition-all group-hover:opacity-100" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.82906 1L7 4M7 4L3.82906 7M7 4L1 4" stroke="#38332C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </div>
        </a>
      </li>
    </ul>
    <p class="text-white pt-8 body-l" v-else-if="noResults">Hittade inga sökresultat.</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
      placeholder: String
  },
  data() {
    return {
      query: '',
      results: [],
      noResults: false,
      debounceTimeout: null,
    };
  },
  methods: {
    performSearch() {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      // Debounce to avoid too many requests
      this.debounceTimeout = setTimeout(() => {
        if (this.query.length > 0) {
          axios
            .get(`/wp-json/wp/v2/search`, {
              params: {
                search: this.query,
              },
            })
            .then((response) => {
              this.results = response.data;
              this.noResults = this.results.length === 0;
            })
            .catch((error) => {
              console.error('Error fetching search results:', error);
            });
        } else {
          this.results = [];
          this.noResults = false;
        }
      }, 300); // Adjust the delay as needed
    },
  },
};
</script>
